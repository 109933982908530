import { DefaultSeoProps } from 'next-seo'

const NEXT_SEO_CONFIG = {
  titleTemplate: '%s - COMPFEST | Main Event | November 12, 2022',
  defaultTitle: 'COMPFEST | Main Event | November 12, 2022',
  description:
    'COMPFEST adalah acara IT Tahunan Terbesar yang diselenggarakan \
    mahasiswa Fakultas Ilmu Komputer Universitas Indonesia. Ikutilah keseruan acara-acara \
    kami di COMPFEST!',
  canonical: `https://www.compfest.id`,
  openGraph: {
    type: 'website',
    title: 'COMPFEST | Main Event | November 12, 2022',
    images: [
      {
        url: '/og-image.jpeg',
        width: 640,
        height: 640,
        alt: 'compfest-logo',
      },
    ],
    url: 'https://www.compfest.id',
    locale: 'en_US',
    site_name: 'COMPFEST',
  },
  twitter: {
    cardType: 'summary',
    site: '@COMPFEST',
    handle: '@COMPFEST',
  },
} as DefaultSeoProps

export default NEXT_SEO_CONFIG
