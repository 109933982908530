import { forwardRef } from 'react'
import { useFormContext } from 'react-hook-form'

import { classNames } from 'utils/functions'
import { CustomInputProps } from 'utils/types/props'

export const Input = forwardRef<HTMLInputElement, CustomInputProps>(
  (
    {
      label,
      name,
      className,
      labelClassName,
      wrapperClassName,
      type,
      id,
      required,
      options,
      onChange,
      rightChildren,
      ...props
    },
    _ref,
  ) => {
    const { register } = useFormContext()

    if (type === 'radio') {
      return (
        <div className={classNames('form-check flex flex-row items-center', wrapperClassName)}>
          <input
            className={classNames(
              'form-check-input h-4 w-4 appearance-none rounded-full border border-[#FFFFFF99] bg-[#2D2F45]',
              'transition duration-200 checked:border-4 checked:border-[#5561FF] checked:bg-white focus:outline-none',
              'placeholder:text-white-2 float-left mr-2 cursor-pointer bg-contain bg-center bg-no-repeat',
            )}
            type="radio"
            id={id ?? name}
            {...props}
            {...register(name, options)}
          />
          <label
            className={`form-check-label inline-block cursor-pointer text-white ${
              labelClassName ?? 'text-[.75rem] md:text-[1rem]'
            }`}
            htmlFor={id ?? name}
          >
            {label}
          </label>
        </div>
      )
    }

    if (type === 'checkbox') {
      return (
        <div className={classNames('flex flex-row items-center', wrapperClassName)}>
          <input
            className={classNames(
              'focus:ring-transparent focus:ring-offset-transparent',
              'form-checkbox h-4 w-4 rounded-[5px] border border-[#FFFFFF99] bg-transparent',
              'transition duration-200 checked:border-[#5561FF] checked:bg-[#5561FF] checked:focus:text-[#5561FF]',
              'float-left mr-2 cursor-pointer bg-contain bg-center bg-no-repeat',
            )}
            type="checkbox"
            id={id ?? name}
            {...props}
            {...register(name, options)}
          />
          <label
            className={`form-check-label inline-block cursor-pointer text-white ${
              labelClassName ?? 'text-[.75rem] md:text-[1rem]'
            }`}
            htmlFor={id ?? name}
          >
            {label}
          </label>
        </div>
      )
    }

    const inputComp = (
      <input
        id={id ?? name}
        className={`placeholder:text-white-2 ${
          rightChildren ? '' : 'mt-3 '
        } w-full rounded-xl border-2 border-[#6E7198] bg-card px-5 py-3 text-white
      focus:border-[#6E7198] focus:outline-none ${className}`}
        {...props}
        {...register(name)}
        type={type}
      />
    )

    return (
      <div className={wrapperClassName}>
        <label htmlFor={id ?? name} className="flex w-full flex-col">
          {label && (
            <span
              className={`mb-1 font-medium ${labelClassName ?? 'text-[.75rem] md:text-[1rem]'}`}
            >
              {label} {required && <span className="text-gum">*</span>}
            </span>
          )}
          {rightChildren ? (
            <div className="mt-3 flex h-full w-full items-center space-x-3">
              {inputComp}
              {rightChildren}
            </div>
          ) : (
            inputComp
          )}
        </label>
      </div>
    )
  },
)
