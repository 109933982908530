import { z } from 'zod'

export enum ResendAndResetEnum {
  RESEND,
  RESET,
}

export const idToken = z.string().min(0)
export const verifyEmailToken = z.string().min(20)

export const password = z
  .string()
  .min(8)
  .max(100)
  .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*]{8,}$/)
  .transform((str) => str.trim())

export const email = z
  .string()
  .email()
  .transform((str) => str.toLowerCase().trim())

export const ResendAndReset = z.object({
  email,
  type: z.nativeEnum(ResendAndResetEnum),
  recaptchaToken: z.string().min(20),
})

export const EmailOnly = z.object({
  email,
})

export const Signup = z.object({
  email,
  password,
})

export const Login = z.object({
  email,
  password: z.string(),
})

export const ForgotPassword = z.object({
  email,
})

export const ResetPassword = z
  .object({
    password,
    passwordConfirmation: password,
    token: z.string(),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: "Passwords don't match",
    // path: ['passwordConfirmation'], // set the path of the error
  })

export const NoTokenResetPassword = z
  .object({
    password,
    passwordConfirmation: password,
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: "Passwords don't match",
    // path: ['passwordConfirmation'], // set the path of the error
  })

export const ChangePassword = z.object({
  currentPassword: z.string(),
  newPassword: password,
})
