import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from 'silicon-design-system'
import { zodResolver } from '@hookform/resolvers/zod'
import { EmailOnly, ResendAndResetEnum } from 'app/auth/validations'
import { Input } from 'app/core/components/Form/Input'
import { Image, PromiseReturnType, Script, useMutation } from 'blitz'
import { z } from 'zod'
import login from 'app/auth/mutations/login'
import toast from 'react-hot-toast'
import { AuthModalWrapper } from './AuthModalWrapper'
import resendAndReset from 'app/auth/mutations/resendConfirmationEmailAndResetPassword'

interface ResetPasswordModalProps {
  open: boolean
  onClose: () => unknown
  onBack: () => unknown
  callbackAfterLogin?: () => unknown
  onSuccess?: (user: PromiseReturnType<typeof login>) => void
  isResetPassword?: boolean
}

type Data = z.infer<typeof EmailOnly>

export const ResetPasswordModal: FC<ResetPasswordModalProps> = ({
  onBack,
  isResetPassword = true,
  ...props
}) => {
  const [resendAndResetMutation, { isLoading }] = useMutation(resendAndReset)
  const ctx = useForm<Data>({
    mode: 'onChange',
    resolver: zodResolver(EmailOnly),
  })

  const {
    formState: { isValid },
  } = ctx

  const onSubmit = async ({ email }: Data) => {
    const SITE_KEY = process.env.BLITZ_PUBLIC_RECAPTCHA_SITE_KEY

    ;(window as any).grecaptcha?.ready(() => {
      ;(window as any).grecaptcha
        ?.execute(SITE_KEY, { action: 'submit' })
        .then(async (recaptchaToken: string) => {
          try {
            if (isResetPassword) {
              await resendAndResetMutation({
                email,
                type: ResendAndResetEnum.RESET,
                recaptchaToken,
              })
              toast.success('Email berhasil dikirm. Silakan cek emailmu.')
              return
            }
            await resendAndResetMutation({
              email,
              type: ResendAndResetEnum.RESEND,
              recaptchaToken,
            })
            toast.success('Email berhasil dikirm. Silakan cek emailmu.')
            ctx.reset()
          } catch (err) {
            if (err.message.includes('seconds')) {
              toast.error('Mohon tunggu beberapa detik sebelum mengirim email lagi.')
            } else {
              toast.error('Something went wrong. Please try again later.')
            }
          }
        })
    })
  }

  const title = isResetPassword ? 'Reset Passwordmu' : 'Kirim Ulang Email Verifikasi'
  const description = isResetPassword
    ? 'Masukkan emailmu untuk mengatur ulang passwordmu'
    : 'Masukkan emailmu untuk mengirim ulang email verifikasimu'

  const buttonText = isResetPassword ? 'Kirim Email' : 'Kirim Ulang Email'

  return (
    <AuthModalWrapper<Data>
      show={props.open}
      {...props}
      title={title}
      ctx={ctx}
      onSubmit={onSubmit}
      description={description}
    >
      <Input name="email" label="Email" placeholder="Masukkan email kamu" className="w-full" />

      <Button
        size="full"
        mt={12}
        mb={12}
        text={buttonText}
        type="submit"
        loading={isLoading}
        disabled={!isValid}
      />

      <Button
        variant="secondary"
        size="full"
        leftIcon={<Image src="/icons/arrow-left-short.svg" width={24} height={24} alt="Back" />}
        text="Kembali"
        bg="#FFFFFF1A"
        onClick={onBack}
      />
    </AuthModalWrapper>
  )
}
