import React from 'react'

const Email = ({ className }) => (
  <svg className={className} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.00944 2.66504C2.53678 2.66504 1.34277 3.85904 1.34277 5.3317V10.665C1.34277 12.1377 2.53678 13.3317 4.00944 13.3317H12.0095C13.4821 13.3317 14.6761 12.1377 14.6761 10.665V5.3317C14.6761 3.85904 13.4821 2.66504 12.0095 2.66504H4.00944ZM4.00944 3.99837H12.0095C12.6815 3.99837 13.2368 4.49171 13.3288 5.13837C12.6901 5.73371 11.7408 6.46437 11.1135 6.91503C9.67012 7.9517 8.40012 8.66503 8.00945 8.66503C7.61878 8.66503 6.34878 7.95237 4.90544 6.91503C4.27811 6.46437 3.66145 5.97704 3.19678 5.5817C2.99878 5.41304 2.85211 5.27704 2.73877 5.16504C2.83077 4.51837 3.33744 3.99837 4.00944 3.99837ZM2.68278 6.90104C4.37478 8.2697 6.85412 9.99036 8.00945 9.99836C8.76212 10.0037 10.0495 9.28503 11.3628 8.3777C12.0421 7.9077 12.7981 7.3497 13.3435 6.8917L13.3428 10.665C13.3428 11.4017 12.7461 11.9984 12.0095 11.9984H4.00944C3.27278 11.9984 2.67611 11.4017 2.67611 10.665L2.68278 6.90104Z"
      fill="white"
    />
  </svg>
)

export default Email
