import { AUTH_MODAL } from 'app/core/copywriting/auth'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from 'silicon-design-system'
import { zodResolver } from '@hookform/resolvers/zod'
import { Login, Signup } from 'app/auth/validations'
import { Input } from 'app/core/components/Form/Input'
import { Image, PromiseReturnType } from 'blitz'
import { z } from 'zod'
import login from 'app/auth/mutations/login'
import { useAuth } from 'app/core/hooks'
import toast from 'react-hot-toast'
import { AuthModalWrapper } from './AuthModalWrapper'

interface RegisterLoginModalProps {
  open: boolean
  onClose: () => unknown
  onBack: () => unknown
  isRegister?: boolean
  callbackAfterLogin?: () => unknown
  onSuccess?: (user: PromiseReturnType<typeof login>) => void
  onResetPassword?: () => unknown
}

type Data = z.infer<typeof Login>

export const RegisterLoginModal: FC<RegisterLoginModalProps> = ({
  isRegister = false,
  onBack,
  onResetPassword,
  ...props
}) => {
  const [loading, setLoading] = useState(false)

  const ctx = useForm<Data>({
    mode: 'onChange',
    resolver: zodResolver(isRegister ? Signup : Login),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const {
    formState: { isValid },
  } = ctx

  const { signup, loginLocal } = useAuth()

  const onSubmit = async (data: Data) => {
    setLoading(true)
    try {
      if (isRegister) {
        const success = await signup(data.email, data.password)
        if (success) {
          ctx.reset()
          props.callbackAfterLogin?.()
        }
      } else {
        const user = await loginLocal(data.email, data.password)
        if (user) {
          props.onSuccess?.(user)
          props.callbackAfterLogin?.()
          props.onClose()
        }
      }
    } catch (error) {
      toast.error('Something went wrong')
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthModalWrapper<Data>
      show={props.open}
      {...props}
      title={isRegister ? AUTH_MODAL.registerTitle : AUTH_MODAL.loginTitle}
      ctx={ctx}
      onSubmit={onSubmit}
    >
      <Input name="email" type="email" label="Email" placeholder="Masukkan email kamu" />
      <Input
        name="password"
        label="Password"
        type="password"
        placeholder={isRegister ? 'Bikin password untuk akunmu' : 'Masukkan password akun kamu'}
        wrapperClassName="mt-4"
        autoComplete="new-password"
      />
      {isRegister ? (
        <div className="mt-2">
          <div className="flex items-center">
            <Image src="/icons/info2-circle.svg" width={16} height={16} alt="info" />
            <p className="ml-2 text-xs">
              Minimal 8 karakter dengan kombinasi huruf kapital dan angka
            </p>
          </div>
          <p
            className="mt-5 cursor-pointer text-xs text-blueberry underline"
            onClick={onResetPassword}
          >
            Kirim ulang email verifikasi
          </p>
        </div>
      ) : (
        <p className="mt-6 text-center font-sans  text-sm font-medium text-white2 sm:text-base md:mt-12">
          Lupa password?{' '}
          <span className="cursor-pointer text-blueberry underline" onClick={onResetPassword}>
            Reset password
          </span>{' '}
          saja!
        </p>
      )}

      <div className="mt-6 flex w-full flex-col md:mt-12 md:flex-row md:justify-between">
        <Button
          variant="secondary"
          size="full"
          leftIcon={<Image src="/icons/arrow-left-short.svg" width={24} height={24} alt="Back" />}
          text="Kembali"
          bg="#FFFFFF1A"
          onClick={onBack}
          type="button"
        />

        <Button
          size="full"
          mt={12}
          rightIcon={
            <Image
              src={`/icons/${isRegister ? 'arrow-right-short' : 'box-arrow-in-right'}.svg`}
              width={24}
              height={24}
              alt="Next"
            />
          }
          text={isRegister ? 'Lanjut' : 'Masuk'}
          type="submit"
          _responsive={{ md: { ml: 12, mt: 0 } }}
          loading={loading}
          disabled={!isValid && isRegister}
        />
      </div>
    </AuthModalWrapper>
  )
}
