import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  UserCredential,
} from 'firebase/auth'
import { auth } from 'integrations/firebase'
import { useFirebaseAuth } from 'contexts/AuthContext'
import { useMutation, useRouter, useSession } from 'blitz'
import { default as blitzLogin } from 'app/auth/mutations/login'
import { default as blitzSignup } from 'app/auth/mutations/signup'
import { default as blitzLogout } from 'app/auth/mutations/logout'
import toast from 'react-hot-toast'
import { EmailAlreadyExist, EmailNotVerified } from 'app/errors'

export const useAuth = () => {
  const { user } = useFirebaseAuth()
  const session = useSession({
    suspense: true,
  })
  const router = useRouter()

  const uid = session?.userId

  let nickname = session.name?.split(' ')[0]
  nickname = nickname ?? user?.displayName?.split(' ')[0]
  nickname = nickname ?? 'User'

  const [loginMutation] = useMutation(blitzLogin)
  const [signupMutation] = useMutation(blitzSignup)
  const [logoutMutation] = useMutation(blitzLogout)

  const signup = async (email: string, password: string) => {
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password)
      const { user } = result
      const idToken = await user?.getIdToken()

      await signupMutation(idToken)
      toast.success('Successfully signed up! Please verify your email.', {
        duration: 5000,
      })
      return true
    } catch (error) {
      const isUnique =
        error?.message?.includes('email') || error?.code === 'auth/email-already-in-use'

      if (error instanceof EmailAlreadyExist || isUnique) {
        toast.error('Email already exists!')
      } else {
        toast.error('Something went wrong')
      }
      return false
    }
  }

  const loginLocal = async (email: string, password: string) => {
    const result = await signInWithEmailAndPassword(auth, email, password)
    return await login(result)
  }

  const loginWithGoogle = async () => {
    const result = await signInWithPopup(auth, new GoogleAuthProvider())
    return await login(result)
  }

  const login = async (creds: UserCredential) => {
    const id = toast.loading('Logging in...')
    try {
      const { user } = creds

      if (!user.emailVerified) {
        throw new EmailNotVerified('Email is not verified')
      }

      const idToken = await user?.getIdToken()
      const userCF = await loginMutation(idToken)
      toast.success('Successfully logged in!', { id })

      return userCF
    } catch (err) {
      if (err instanceof EmailNotVerified) {
        toast.error('Email is not verified', { id })
      } else {
        toast.error('Inncorrect email or password', { id })
      }
      await logout(false)
    }
  }

  const logout = async (redirect = true) => {
    await signOut(auth)
    await logoutMutation()
    if (redirect) router.push('/')
  }

  return {
    idToken: uid ? user?.idToken : '',
    signup,
    loginLocal,
    loginWithGoogle,
    logout,
    uid,
    isLoggedIn: !!uid,
    nickname,
    name: session.name ?? user?.displayName,
    onboarded: session.onboarded,
  }
}
