import { useState, useEffect } from 'react'

export function getSessionStorageValue(key, defaultValue) {
  if (typeof window !== 'undefined') {
    const saved = sessionStorage.getItem(key)
    const initial = saved !== null ? JSON.parse(saved) : defaultValue
    return initial
  }
}

export function setSessionStorage(key, val) {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(key, JSON.stringify(val))
  }
}

export const useSessionStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getSessionStorageValue(key, defaultValue)
  })

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}
