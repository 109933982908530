import React from 'react'

export const Trash = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9981 1.95312C10.8931 1.95312 9.99805 2.84812 9.99805 3.95312H4.99805C4.44605 3.95312 3.99805 4.40112 3.99805 4.95312C3.99805 5.50511 4.44605 5.95311 4.99805 5.95311H18.9981C19.5501 5.95311 19.9981 5.50511 19.9981 4.95312C19.9981 4.40112 19.5501 3.95312 18.9981 3.95312H13.9981C13.9981 2.84812 13.1031 1.95312 11.9981 1.95312ZM4.99805 7.95311V17.9531C4.99805 20.1431 6.80305 21.9221 8.99805 21.9221L15.0291 21.9531C17.2241 21.9531 18.9981 20.1481 18.9981 17.9531V7.95311H4.99805ZM9.99805 10.9531C10.5501 10.9531 10.9981 11.4011 10.9981 11.9531V17.9531C10.9981 18.5051 10.5501 18.9531 9.99805 18.9531C9.44605 18.9531 8.99805 18.5051 8.99805 17.9531V11.9531C8.99805 11.4011 9.44605 10.9531 9.99805 10.9531ZM13.9981 10.9531C14.5501 10.9531 14.9981 11.4011 14.9981 11.9531V17.9531C14.9981 18.5051 14.5501 18.9531 13.9981 18.9531C13.4461 18.9531 12.9981 18.5051 12.9981 17.9531V11.9531C12.9981 11.4011 13.4461 10.9531 13.9981 10.9531Z"
      fill="white"
    />
  </svg>
)
