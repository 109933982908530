import { useQuery } from 'blitz'
import getCurrentUser from 'app/users/queries/getCurrentUser'

export const useCurrentUser = () => {
  const [user, { refetch }] = useQuery(getCurrentUser, null)
  const dobYear = user?.dob?.getFullYear()
  let dobMonth = user?.dob?.getMonth() ?? 0
  const dobDay = user?.dob?.getDate() ?? 1

  dobMonth++

  const dobMonthStr = dobMonth.toString().padStart(2, '0')
  const dobDayStr = dobDay.toString().padStart(2, '0')
  return { ...user, dobStr: `${dobYear}-${dobMonthStr}-${dobDayStr}`, refetch }
}
