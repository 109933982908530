import React from 'react'

export const Trophy = ({ className }) => (
  <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M7.49121 2.92969C5.28221 2.92969 3.49121 4.72069 3.49121 6.92969C3.49121 8.64169 4.4452 9.95168 5.9602 10.8987C6.6262 11.3147 7.0622 11.5137 7.5552 11.6727C7.8332 12.9647 8.82421 14.0967 10.4912 14.9297V17.9297H9.99121C9.16321 17.9297 8.49121 18.6017 8.49121 19.4297C8.49121 20.2577 9.16321 20.9297 9.99121 20.9297H10.4912H14.4912H14.9912C15.8192 20.9297 16.4912 20.2577 16.4912 19.4297C16.4912 18.6017 15.8192 17.9297 14.9912 17.9297H14.4912V14.9297C16.1582 14.0967 17.0882 13.0587 17.4152 11.6877C17.8582 11.5477 18.3562 11.3147 19.0222 10.8987C20.5372 9.95168 21.4912 8.64169 21.4912 6.92969C21.4912 4.72069 19.7002 2.92969 17.4912 2.92969H16.4912H8.49121H7.49121ZM7.49121 4.92969V9.46069C7.33421 9.37769 7.17321 9.30469 7.02221 9.21069C6.03721 8.59469 5.49121 7.84269 5.49121 6.92969C5.49121 5.82469 6.38621 4.92969 7.49121 4.92969ZM17.4912 4.92969C18.5962 4.92969 19.4912 5.82469 19.4912 6.92969C19.4912 7.84269 18.9452 8.59469 17.9602 9.21069C17.8092 9.30469 17.6482 9.37769 17.4912 9.46069V4.92969Z"
      fill="white"
    />
  </svg>
)
