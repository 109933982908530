import {
  JobApplicationStatus,
  JobExperience,
  JobMinimumEducation,
  JobPosition,
} from '@prisma/client'
import { ApplicationQuery, ApplicationQueryDTO, NormalizedQuery } from 'utils/types/filterDTOs'

function castQuery({
  EDU,
  EXP,
  LOCATION,
  SEARCH,
  APPLIED,
  BOOKMARKED,
  POSITION,
  TAGS,
  PREF,
}: ApplicationQueryDTO): ApplicationQuery {
  return {
    APPLIED: !APPLIED ? '' : Array.isArray(APPLIED) ? APPLIED[0] ?? '' : APPLIED,
    BOOKMARKED: !BOOKMARKED ? '' : Array.isArray(BOOKMARKED) ? BOOKMARKED[0] ?? '' : BOOKMARKED,
    POSITION: !POSITION ? [] : !Array.isArray(POSITION) ? [POSITION] : POSITION,
    TAGS: !TAGS ? [] : !Array.isArray(TAGS) ? [TAGS] : TAGS,
    PREF: !PREF ? '' : Array.isArray(PREF) ? PREF[0] ?? '' : PREF,
    SEARCH: !SEARCH ? '' : Array.isArray(SEARCH) ? SEARCH[0] ?? '' : SEARCH,
    EDU: !EDU ? [] : !Array.isArray(EDU) ? [EDU] : EDU,
    EXP: !EXP ? [] : !Array.isArray(EXP) ? [EXP] : EXP,
    LOCATION: !LOCATION ? [] : !Array.isArray(LOCATION) ? [LOCATION] : LOCATION,
  }
}

export function normalizeQuery(app: ApplicationQueryDTO): NormalizedQuery {
  const { EDU, EXP, LOCATION, SEARCH, APPLIED, BOOKMARKED, POSITION, TAGS, PREF } = castQuery(app)
  return {
    SEARCH,
    LOCATION,
    EDU: EDU as JobMinimumEducation[],
    EXP: EXP as JobExperience[],
    TAGS: TAGS,
    POSITION: POSITION as JobPosition[],
    APPLIED: APPLIED == 'true' ? 'true' : '',
    BOOKMARKED: BOOKMARKED == 'true' ? 'true' : '',
    PREF: PREF == 'WFO' ? 'WFO' : PREF == 'WFH' ? 'WFH' : 'NONE',
  }
}
