import {
  AppProps,
  ErrorBoundary,
  AuthenticationError,
  AuthorizationError,
  ErrorFallbackProps,
  useQueryErrorResetBoundary,
  useRouter,
  Script,
} from 'blitz'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Toaster } from 'app/core/components/Toast'
import { DefaultSEO } from 'lib/seo'
import Sentry from 'integrations/sentry'
import 'react-loading-skeleton/dist/skeleton.css'
import CustomErrorComponent from 'app/core/components/Error/Custom'
import 'app/core/styles/index.css'
import { AuthenticationModal } from 'app/auth/components/AuthModal'
import { AuthContextProvider } from 'contexts/AuthContext'
import { NotificationContextProvider } from 'contexts/NotificationContext'
import { useEffect } from 'react'
import posthog from 'posthog-js'
import { AnimateSharedLayout } from 'framer-motion'
import { FilterProvider } from 'contexts/Filter'

export default function App({ Component, pageProps }: AppProps) {
  const getLayout = Component.getLayout || ((page) => page)
  const router = useRouter()
  const SITE_KEY = process.env.BLITZ_PUBLIC_RECAPTCHA_SITE_KEY

  useEffect(() => {
    if (window.location.href.includes('compfest') && process.env.BLITZ_PUBLIC_POSTHOG_API_KEY) {
      posthog.init(process.env.BLITZ_PUBLIC_POSTHOG_API_KEY, {
        api_host: process.env.BLITZ_PUBLIC_POSTHOG_API_HOST ?? 'https://app.posthog.com',
      })
    }
  }, [])

  useEffect(() => {
    const fbPixelId = process.env.BLITZ_PUBLIC_FBPIXEL_ID

    if (fbPixelId) {
      import('react-facebook-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init(fbPixelId)
          ReactPixel.pageView()

          const handleRouteChange = (url) => {
            ReactPixel.pageView()
          }

          router.events.on('routeChangeComplete', handleRouteChange)
          return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
          }
        })
    }
  }, [router.events])

  return (
    <ErrorBoundary
      FallbackComponent={RootErrorFallback}
      onReset={useQueryErrorResetBoundary().reset}
      onError={(error, componentStack) => {
        Sentry.captureException(error, { contexts: { react: { componentStack } } })
      }}
      resetKeys={[router.asPath]}
    >
      <DefaultSEO />
      <Toaster />
      <AuthContextProvider>
        <NotificationContextProvider>
          <FilterProvider>
            <AnimateSharedLayout>{getLayout(<Component {...pageProps} />)}</AnimateSharedLayout>
          </FilterProvider>
        </NotificationContextProvider>
      </AuthContextProvider>
      <Script src={`https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`} />
      <ReactQueryDevtools initialIsOpen={false} />
    </ErrorBoundary>
  )
}

function RootErrorFallback({ error }: ErrorFallbackProps) {
  const router = useRouter()
  if (error instanceof AuthenticationError) {
    return (
      <>
        <Toaster />
        <AuthenticationModal
          open={true}
          onClose={() => null}
          withCloseIcon={false}
          callbackAfterLogin={() => router.reload()}
        />
      </>
    )
  } else if (error instanceof AuthorizationError) {
    return (
      <CustomErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    )
  } else {
    return (
      <CustomErrorComponent
        statusCode={error.statusCode || 400}
        title={error.message || error.name}
      />
    )
  }
}
