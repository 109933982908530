import React from 'react'

const Whatsapp = ({ className }) => (
  <svg viewBox="0 0 16 16" className={className} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99919 1.30176C4.31719 1.30176 1.33252 4.28656 1.33252 7.96842C1.33252 8.92442 1.54585 9.87655 1.95785 10.7601C1.41118 13.5834 1.35319 13.8434 1.35319 13.8434C1.26386 14.3058 1.66118 14.7018 2.12451 14.6143C2.12451 14.6143 2.37718 14.5706 5.22852 14.031C6.08918 14.44 7.04319 14.6351 7.99919 14.6351C11.6812 14.6351 14.6659 11.6503 14.6659 7.96842C14.6659 4.28656 11.6812 1.30176 7.99919 1.30176ZM7.99919 2.63509C10.9445 2.63509 13.3325 5.02289 13.3325 7.96842C13.3325 10.914 10.9445 13.3018 7.99919 13.3018C7.16119 13.3018 6.35651 13.105 5.62451 12.7392C5.49385 12.6742 5.35053 12.6496 5.20719 12.6768C2.84053 13.1248 3.02852 13.098 2.83252 13.1351C2.87119 12.9367 2.83785 13.1426 3.29118 10.8018C3.31918 10.6568 3.29452 10.4961 3.22852 10.3643C2.85652 9.62716 2.66585 8.81349 2.66585 7.96842C2.66585 5.02289 5.05385 2.63509 7.99919 2.63509ZM6.12451 4.63509C5.49918 4.63509 4.66585 5.46842 4.66585 6.09342C4.66585 6.89889 5.49919 8.38509 6.33252 9.21842C6.42252 9.30809 6.65919 9.54542 6.74919 9.63509C7.58252 10.4684 9.06853 11.3018 9.87386 11.3018C10.4992 11.3018 11.3325 10.4684 11.3325 9.84342C11.3325 9.21842 10.4992 8.38509 9.87386 8.38509C9.66586 8.38509 8.91185 8.81609 8.83252 8.80175C8.16852 8.68189 7.30452 7.79696 7.16585 7.13509C7.14652 7.04269 7.58252 6.30176 7.58252 6.09342C7.58252 5.46842 6.74918 4.63509 6.12451 4.63509Z"
      fill="white"
    />
  </svg>
)

export default Whatsapp
