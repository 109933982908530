import { Toaster as T } from 'react-hot-toast'

import CheckCircle from 'public/icons/check-circle.svg'
import ExclCircle from 'public/icons/exclamation-circle.svg'

export const Toaster = () => (
  <T
    position="top-center"
    reverseOrder={false}
    toastOptions={{
      style: {
        boxShadow: '0px 12px 16px 0px #0000004D',
        padding: '12px 16px',
        borderRadius: '12px',
        fontWeight: 700,
        fontSize: '16px',
        color: '#00000080',
        fontFamily: 'Inter, sans-serif',
        boxSizing: 'border-box',
        lineHeight: '1.5',
        width: 'fit-content',
        overflow: 'hidden',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        zIndex: '9999',
      },
      success: {
        icon: (
          <div className="h-6 w-6">
            <CheckCircle />
          </div>
        ),
        style: {
          background: '#3EEBBE',
          border: '2px solid #68FCD6',
        },
      },
      error: {
        icon: (
          <div className="h-6 w-6">
            <ExclCircle />
          </div>
        ),
        style: {
          background: '#FF7387',
          border: '2px solid #FF96A5',
        },
      },
      loading: {
        style: {
          background: '#373951',
          border: '2px solid #3E405B',
          color: 'white',
        },
      },
    }}
  />
)
