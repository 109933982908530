import login from 'app/auth/mutations/login'
import { PromiseReturnType } from 'blitz'
import { FC, useState } from 'react'
import { AuthModal } from './AuthModal'
import { RegisterLoginModal } from './RegisterLoginModal'
import { ResetPasswordModal } from './ResetPasswordModal'

interface AuthenticationModalProps {
  open: boolean
  onClose: () => unknown
  withCloseIcon?: boolean
  callbackAfterLogin?: () => unknown
  onSuccess?: (user: PromiseReturnType<typeof login>) => void
}

enum AuthState {
  INITIAL = 'intial',
  LOGIN = 'login',
  REGISTER = 'register',
  RESET_PASSWORD = 'reset_password',
  RESEND_EMAIL_VERICATION = 'email_verification',
}

export const AuthenticationModal: FC<AuthenticationModalProps> = ({ onClose, ...props }) => {
  const [authState, setAuthState] = useState<AuthState>(AuthState.INITIAL)
  const isInitial = authState === AuthState.INITIAL
  const isLogin = authState === AuthState.LOGIN
  const isRegister = authState === AuthState.REGISTER
  const isResetPassword = authState === AuthState.RESET_PASSWORD
  const isResendEmailVerification = authState === AuthState.RESEND_EMAIL_VERICATION

  if (!props.open) return null

  const onCloseAuthModal = () => {
    onClose()
    setAuthState(AuthState.INITIAL)
  }

  switch (authState) {
    case AuthState.INITIAL:
      return (
        <AuthModal
          {...props}
          open={isInitial}
          onLocalLogin={() => setAuthState(AuthState.LOGIN)}
          onRegister={() => setAuthState(AuthState.REGISTER)}
          onClose={onCloseAuthModal}
        />
      )
    case AuthState.LOGIN:
      return (
        <RegisterLoginModal
          {...props}
          open={isLogin}
          onBack={() => setAuthState(AuthState.INITIAL)}
          onClose={onCloseAuthModal}
          onResetPassword={() => setAuthState(AuthState.RESET_PASSWORD)}
        />
      )
    case AuthState.REGISTER:
      return (
        <RegisterLoginModal
          {...props}
          open={isRegister}
          onBack={() => setAuthState(AuthState.INITIAL)}
          onClose={onCloseAuthModal}
          callbackAfterLogin={() => setAuthState(AuthState.LOGIN)}
          onResetPassword={() => setAuthState(AuthState.RESEND_EMAIL_VERICATION)}
          isRegister
        />
      )
    case AuthState.RESET_PASSWORD:
      return (
        <ResetPasswordModal
          {...props}
          open={isResetPassword}
          onClose={onCloseAuthModal}
          onBack={() => setAuthState(AuthState.LOGIN)}
        />
      )
    case AuthState.RESEND_EMAIL_VERICATION:
      return (
        <ResetPasswordModal
          {...props}
          open={isResendEmailVerification}
          onClose={onCloseAuthModal}
          onBack={() => setAuthState(AuthState.REGISTER)}
          isResetPassword={false}
        />
      )
    default:
      return null
  }
}
