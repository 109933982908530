import { Dialog } from '@headlessui/react'
import { Image, PromiseReturnType } from 'blitz'
import { AUTH_MODAL } from 'app/core/copywriting/auth'
import { FC } from 'react'
import { Button } from 'silicon-design-system'
import { useAuth } from 'app/core/hooks'
import login from 'app/auth/mutations/login'
import { CloseIcon } from 'app/core/components/Icon'

export interface AuthModalProps {
  open: boolean
  onClose: () => unknown
  withCloseIcon?: boolean
  onRegister: () => unknown
  onLocalLogin: () => unknown
  callbackAfterLogin?: () => unknown
  onSuccess?: (user: PromiseReturnType<typeof login>) => void
}

export const AuthModal: FC<AuthModalProps> = ({
  withCloseIcon = true,
  onClose,
  onLocalLogin,
  onRegister,
  callbackAfterLogin,
  onSuccess,
  ...props
}) => {
  const { loginWithGoogle } = useAuth()

  const googleLogin = async () => {
    const user = await loginWithGoogle()
    if (user) {
      callbackAfterLogin?.()
      onSuccess?.(user)
      onClose()
    }
  }

  return (
    <Dialog
      {...props}
      open={true}
      onClose={onClose}
      as="div"
      className="fixed inset-0 z-100 overflow-y-auto"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
      <div className="flex min-h-screen items-center justify-center px-4 text-center">
        <div className="my-8 inline-block max-w-[350px] transform overflow-hidden rounded-3xl bg-[#2D2F45] text-left align-middle shadow-xl transition-all md:flex md:max-w-[800px] md:justify-center">
          {/* LEFT */}
          <div className="relative w-full flex-col justify-center bg-illustrations bg-cover py-8 px-6 smm:py-10 smm:px-8 md:w-1/2 md:p-10">
            {withCloseIcon && <CloseIcon className="mb-4 flex md:hidden" onClose={onClose} />}
            <p className="mb-6 text-center text-base font-bold text-white2 md:text-xl">
              {AUTH_MODAL.title1}
            </p>
            <ul>
              {AUTH_MODAL.benefits.map(({ text, className, icon }) => (
                <li
                  key={text}
                  className={`mb-2 flex w-full items-center rounded-lg px-6 py-2 font-sans text-xs font-medium backdrop-blur-sm md:text-base ${className}`}
                >
                  <Image src={icon} width={24} height={24} alt={text} />
                  <span className="ml-1">{text}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* RIGHT */}
          <div className="smm:px-88 flex w-full flex-col items-center bg-card py-8 px-6 smm:py-10 md:w-1/2 md:p-10">
            {withCloseIcon && <CloseIcon className="mb-4 hidden md:flex" onClose={onClose} />}
            <p className="mb-6 text-center font-rflex text-2xl font-bold md:text-3xl">
              {AUTH_MODAL.title2}
            </p>

            <div className="w-full font-sans text-xs md:text-base">
              <Button
                variant="ghost"
                size="full"
                mb={12}
                onClick={onLocalLogin}
                bg="#373951"
                leftIcon={<Image src="/icons/envelope.svg" width={24} height={24} alt="email" />}
                text={AUTH_MODAL.loginBtnEmail}
                fs={12}
                _responsive={{ md: { fs: 16 } }}
              />

              <Button
                variant="ghost"
                size="full"
                bg="#373951"
                leftIcon={<Image src="/icons/google.svg" width={24} height={24} alt="google" />}
                text={AUTH_MODAL.loginBtnGoogle}
                onClick={googleLogin}
                fs={12}
                _responsive={{ md: { fs: 16 } }}
              />
            </div>

            <p className="mt-6 font-sans text-xs font-medium text-white2 md:text-base">
              Belum punya?{' '}
              <span className="cursor-pointer text-blueberry underline" onClick={onRegister}>
                Bikin akun
              </span>{' '}
              yuk!
            </p>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
