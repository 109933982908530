export const AUTH_MODAL = {
  title1: 'Dengan menggunakan akun COMPFEST, kamu bisa:',
  title2: 'Let’s make your account!',
  benefits: [
    {
      text: 'Explorasi perusahaan IT',
      icon: '/icons/binoculars.svg',
      className: 'bg-blueberryLight15 text-blueberry',
    },
    {
      text: 'Menonton seminar',
      icon: '/icons/play-btn.svg',
      className: 'bg-bananaLight15 text-banana',
    },
    {
      text: 'Bermain game seru',
      icon: '/icons/controller.svg',
      className: 'bg-gumLight15 text-gum',
    },
    { text: 'Menangkan hadiah', icon: '/icons/gift.svg', className: 'bg-mintLight15 text-mint' },
  ],
  loginBtnEmail: 'Masuk dengan Email',
  loginBtnGoogle: 'Masuk dengan Google',
  loginTitle: 'Let’s log in to your account first!',
  registerTitle: 'Let’s make your account!',
}
