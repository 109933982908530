import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from 'integrations/firebase'
import { User } from 'firebase/auth'

interface UserWithToken extends User {
  token: string
  idToken: string
}

interface IAuthContext {
  user: UserWithToken | null
  setUser: Dispatch<SetStateAction<UserWithToken | null>>
}

const AuthContext = createContext<IAuthContext>({
  user: null,
  setUser: () => {},
})

export const useFirebaseAuth = () => useContext(AuthContext)

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<IAuthContext['user']>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        setUser(null)
        setLoading(false)
        return
      }

      const token = await user.getIdTokenResult()
      const idToken = await user.getIdToken()

      setUser({
        ...user,
        token: token.token,
        idToken,
      })
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  // force refresh the token every 10 minutes
  useEffect(() => {
    const handle = setInterval(async () => {
      console.info(`Refreshing JWT Token`)
      const user = auth.currentUser
      if (user) await user.getIdTokenResult(true)
    }, 10 * 60 * 1000)
    return () => clearInterval(handle)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {loading ? null : children}
    </AuthContext.Provider>
  )
}
