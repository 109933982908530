import { PrivateNotifications } from 'db'
import getPrivateNotifications from 'app/notifications/queries/getPrivateNotifications'
import { invalidateQuery, useInfiniteQuery } from 'blitz'
import { createContext, FC, useState } from 'react'
import hasNewNotification from 'app/notifications/queries/hasNewNotification'

type ReturnTypeOfGetPrivateNotifications = Awaited<ReturnType<typeof getPrivateNotifications>>[]

type INotificationContext = {
  open: boolean
  onClose: () => unknown
  onOpen: () => unknown
  data: ReturnTypeOfGetPrivateNotifications | undefined
  isFetching: boolean
  isFetchingNextPage: boolean
  fetchNextPage: () => unknown
  hasNextPage: boolean | undefined
  notifications: PrivateNotifications[]
  hasNew: boolean
}

export const NotificationContext = createContext<INotificationContext>({
  open: false,
  onClose: () => {},
  onOpen: () => {},
  data: undefined,
  isFetching: false,
  isFetchingNextPage: false,
  fetchNextPage: () => {},
  hasNextPage: undefined,
  notifications: [],
  hasNew: false,
})

export const NotificationContextProvider: FC = ({ children }) => {
  const [showNotifications, setShowNotifications] = useState(false)
  const onClose = async () => {
    setShowNotifications(false)
    await invalidateQuery(hasNewNotification)
  }
  const onOpen = () => setShowNotifications(true)

  const [data, { isFetching, isFetchingNextPage, fetchNextPage, hasNextPage }] = useInfiniteQuery(
    getPrivateNotifications,
    (page = { take: 10, skip: 0 }) => page,
    {
      getNextPageParam: (lastPage) => lastPage?.nextPage,
      suspense: false,
      enabled: showNotifications,
    },
  )

  const notifications: PrivateNotifications[] = []
  data?.forEach((page) => {
    const items = page?.items
    if (items) {
      notifications.push(...items)
    }
  })

  const value = {
    open: showNotifications,
    onClose,
    onOpen,
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    notifications,
    hasNew: notifications.length > 0 && !notifications[0]?.read,
  }

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>
}
