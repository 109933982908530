import { useEffect, useState } from 'react'
import { calculateTimeLeft } from 'utils/functions'

export const useCountdown = (endTime: Date | string) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endTime))

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft(endTime))
    }, 1000)
  })

  return timeLeft
}
