import { Image } from 'blitz'
import { FC } from 'react'

interface CloseIconProps {
  className?: string
  iconClassName?: string
  onClose?: () => unknown
  pos?: 'left' | 'right' | 'mid'
}

export const CloseIcon: FC<CloseIconProps> = ({
  className,
  onClose,
  pos = 'right',
  iconClassName,
}) => {
  let justify = 'justify-end'

  if (pos === 'left') justify = 'justify-start'
  if (pos === 'mid') justify = 'justify-center'

  return (
    <div className={`flex w-full items-center ${justify} ${className}`}>
      <div onClick={onClose} className={`cursor-pointer ${iconClassName}`}>
        <Image src="/icons/x.svg" width={24} height={24} alt="close" />
      </div>
    </div>
  )
}
