import { useRouter } from 'blitz'
import { FC, createContext, useEffect, useState } from 'react'
import { normalizeQuery } from 'utils/functions/normalizeQuery'
import { ApplicationFilterKey, ApplicationQueryDTO, NormalizedQuery } from 'utils/types/filterDTOs'

interface FilterContextInterface extends NormalizedQuery {
  go: (key: ApplicationFilterKey, chosenValue: string) => void
  show: boolean
  close: () => void
  open: () => void
  reset: () => void
}

const INITIAL_VALUES: FilterContextInterface = {
  EDU: [],
  EXP: [],
  LOCATION: [],
  SEARCH: '',
  APPLIED: '',
  BOOKMARKED: '',
  POSITION: [],
  TAGS: [],
  PREF: 'NONE',
  go: (_key: ApplicationFilterKey, _chosenValue: string) => {},
  close: () => {},
  open: () => {},
  show: false,
  reset: () => {},
}
export const FilterContext = createContext<FilterContextInterface>(INITIAL_VALUES)

export const FilterProvider: FC = ({ children }) => {
  const router = useRouter()
  const [show, setShowModal] = useState<boolean>(false)
  const [query, setQuery] = useState<NormalizedQuery>({
    ...INITIAL_VALUES,
  })
  useEffect(() => {
    setQuery(normalizeQuery(router.query as unknown as ApplicationQueryDTO))
  }, [router])

  const reset = () => {
    const { close, go, show, open, reset, ...q } = INITIAL_VALUES
    router.push({
      query: {
        ...q,
      },
    })
  }

  function go(key: ApplicationFilterKey, chosenValue: string) {
    let q: ApplicationQueryDTO
    if (key == 'SEARCH' || key == 'PREF' || key == 'APPLIED' || key == 'BOOKMARKED') {
      q = { ...query, [key]: chosenValue }
    } else {
      // check if val already exists.
      let values = query[key] as string[]
      if (values.includes(chosenValue)) {
        values = values.filter((value) => value != chosenValue)
      } else {
        values.push(chosenValue)
      }
      q = { ...query, [key]: values }
    }
    router.push({
      query: {
        ...q,
      },
    })
  }
  return (
    <FilterContext.Provider
      value={{
        ...query,
        go,
        show,
        close: () => setShowModal(false),
        open: () => setShowModal(true),
        reset,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}
