// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { Analytics, getAnalytics, isSupported } from 'firebase/analytics'
import { config } from 'config'
import { getAuth } from 'firebase/auth'

const { firebase } = config
const firebaseConfig = firebase

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

export const initializeAnalytics = async () => {
  if (await isSupported()) {
    return getAnalytics(app)
  }
}

const analytics: Promise<Analytics | undefined> = initializeAnalytics()

export { app, auth, analytics }
