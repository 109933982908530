import { ReactNode } from 'react'
import { FieldValues, FormProvider, UnpackNestedValue, UseFormReturn } from 'react-hook-form'
import { Modal } from 'silicon-design-system'

interface AuthModalWrapperProps<T> {
  title: string
  show: boolean
  onClose: () => unknown
  ctx: UseFormReturn<T>
  onSubmit: (data: UnpackNestedValue<T>) => unknown
  children: ReactNode
  description?: string
}

export const AuthModalWrapper = <T extends FieldValues>({
  title,
  children,
  ctx,
  onSubmit,
  description,
  ...props
}: AuthModalWrapperProps<T>) => {
  return (
    <Modal width="80%" _responsive={{ sm: { w: 480 } }} {...props}>
      <div className="w-full">
        <h4 className="mb-5 text-center font-rflex text-xl font-bold sm:text-3xl">{title}</h4>
        {description && (
          <p className="mb-5 text-center font-sans text-sm font-medium text-white2 sm:text-base">
            {description}
          </p>
        )}
        <FormProvider {...ctx}>
          <form onSubmit={ctx.handleSubmit(onSubmit)} className="w-full">
            {children}
          </form>
        </FormProvider>
      </div>
    </Modal>
  )
}
