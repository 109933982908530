import { RefObject, useEffect } from 'react'

export const useOutsideClick = <T extends HTMLElement>(
  ref: RefObject<T>,
  callback: (event: MouseEvent) => unknown,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as T)) {
        callback(event)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback])
}
