import { useEffect, useState } from 'react'

export const useTimer = (timeLeft: number) => {
  const [time, setTime] = useState(timeLeft)

  useEffect(() => {
    const interval = setInterval(() => {
      if (time >= 0) setTime((prev) => prev - 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [timeLeft, setTime, time])

  return { time, restart: () => setTime(timeLeft) }
}
