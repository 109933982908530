export const config = {
  isProduction: process.env.NODE_ENV === 'production',
  firebase: {
    apiKey: process.env.BLITZ_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.BLITZ_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.BLITZ_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.BLITZ_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.BLITZ_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.BLITZ_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.BLITZ_PUBLIC_FIREBASE_MEASUREMENT_ID,
  },
  production: {
    API_BASE_URL: process.env.API_BASE_URL,
    API_KEY: process.env.API_KEY,
  },
  development: {
    API_BASE_URL: 'http://localhost:3001/api',
    API_KEY: 'compfest14',
  },
}

export const apiConfig = {
  ...config[process.env.NODE_ENV],
}
