import React from 'react'

export const ZipFile = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99805 2.01367C5.78895 2.01367 3.99805 3.80457 3.99805 6.01367V18.0137C3.99805 20.2228 5.78895 22.0137 7.99805 22.0137H15.998C18.2071 22.0137 19.998 20.2228 19.998 18.0137V6.01367C19.998 3.80457 18.2071 2.01367 15.998 2.01367H7.99805ZM7.99805 4.01367H15.998C17.1026 4.01367 17.998 4.90907 17.998 6.01367V18.0137C17.998 19.1183 17.1026 20.0137 15.998 20.0137H7.99805C6.89345 20.0137 5.99805 19.1183 5.99805 18.0137V6.01367C5.99805 4.90907 6.89345 4.01367 7.99805 4.01367ZM8.99805 5.01367C8.44575 5.01367 7.99805 5.46137 7.99805 6.01367C7.99805 6.56597 8.44575 7.01367 8.99805 7.01367H9.99805C10.5503 7.01367 10.998 6.56597 10.998 6.01367C10.998 5.46137 10.5503 5.01367 9.99805 5.01367H8.99805ZM8.99805 8.01367C8.44575 8.01367 7.99805 8.46137 7.99805 9.01367C7.99805 9.56597 8.44575 10.0137 8.99805 10.0137H9.99805C10.5503 10.0137 10.998 9.56597 10.998 9.01367C10.998 8.46137 10.5503 8.01367 9.99805 8.01367H8.99805ZM8.99805 11.0137C8.44575 11.0137 7.99805 11.4614 7.99805 12.0137C7.99805 12.566 8.44575 13.0137 8.99805 13.0137H9.99805C10.5503 13.0137 10.998 12.566 10.998 12.0137C10.998 11.4614 10.5503 11.0137 9.99805 11.0137H8.99805ZM8.99805 14.0137C8.44575 14.0137 7.99805 14.4614 7.99805 15.0137V17.0137C7.99805 17.566 8.44575 18.0137 8.99805 18.0137H9.99805C10.5503 18.0137 10.998 17.566 10.998 17.0137V15.0137C10.998 14.4614 10.5503 14.0137 9.99805 14.0137H8.99805Z"
      fill="white"
      fillOpacity="0.8"
    />
  </svg>
)
